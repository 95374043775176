const importIcons = r => {
  const icons = {};
  const regExp = new RegExp('^./', 'i');
  r.keys().forEach(item => {
    if (regExp.test(item)) icons[item.replace('./', '').replace('.svg', '')] = r(item);
  });
  return icons;
};

export default importIcons;
