import * as React from 'react';

import Seo from 'components/Seo';
import Benefits from 'sections/Benefits';
// import Faq from 'sections/Faq';
import Hero from 'sections/Hero';
import HowItWorks from 'sections/HowItWorks';
import MakeLearningEasier from 'sections/MakeLearningEasier';
import Opinions from 'sections/Opinions';
import Pricing from 'sections/Pricing';
import Subjects from 'sections/Subjects';
import Schools from 'sections/Schools';

const IndexPage = () => (
  <>
    <Seo title='Moja matura | Aplikacja ułatwiająca przygotowanie do matury' />
    <Hero />
    <HowItWorks />
    <MakeLearningEasier />
    <Benefits />
    <Opinions />
    <Subjects />
    <Schools />
    <Pricing />
     {/*<Faq /> */}
  </>
);

export default IndexPage;
