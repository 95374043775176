import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Section from 'components/Section';
import SwipeIcon from 'images/svg/swipe_icon.svg';
import * as common from 'styles/common.module.scss';
import * as mq from 'styles/mq.module.scss';
import isBrowser from 'utils/isBrowser';

import * as styles from './Opinions.module.scss';

const query = graphql`
  query OpinionsQuery {
    strapiOpinions {
      id
      title
      images_row {
        id
        images_row {
          id
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`;

const renderImagesList = (images, scroll) =>
  scroll
    ? images
        .map(({ images_row }) => images_row)
        .flat()
        .map(({ id, alternativeText, localFile }) => (
          <div key={id} className={styles.imageWrapper}>
            <GatsbyImage alt={alternativeText} className={styles.image} image={getImage(localFile)} />
          </div>
        ))
    : images.map(row => (
        <div key={row.id}>
          {row.images_row.map(({ id, alternativeText, localFile }) => (
            <GatsbyImage key={id} alt={alternativeText} className={styles.image} image={getImage(localFile)} />
          ))}
        </div>
      ));

const Opinions = () => {
  const { strapiOpinions } = useStaticQuery(query);
  const [scroll, setScroll] = useState(false);

  const updateSize = () => {
    if (isBrowser) {
      const width = window.innerWidth;
      setScroll(width <= 1020);
    }
  };

  useEffect(() => {
    if (isBrowser) {
      updateSize();
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
    }
    return () => {};
  }, []);

  return (
    <Section _scrollId='Opinie (5)' className={clsx(common.container, styles.opinions)}>
      <h2 className={common.sectionTitle}>{strapiOpinions.title}</h2>
      <div className={styles.swipeIconWrapper}>
        <SwipeIcon className={mq.hideOverM} />
      </div>
      <div className={styles.content}>{renderImagesList(strapiOpinions.images_row, scroll)}</div>
    </Section>
  );
};

export default Opinions;
