import React, {useMemo, useState} from 'react';

import ChevronIcon from 'images/svg/chevron.svg';

import * as styles from './Accordion.module.scss';
import clsx from "clsx";

const Accordion = ({title, content, initialOpen = false, controlled, onClick, isOpen, id, customTitleContainer, className, featured}) => {
    const [isOpenLocal, setIsOpenLocal] = useState(initialOpen);

    const toggle = () => setIsOpenLocal(prev => !prev)

    const resolveOnClick = () => {
        if (controlled) return onClick(id)
        else return toggle()

    }

    const resolvedIsOpen = useMemo(() => {
        if (controlled) return isOpen
        else return isOpenLocal
    }, [controlled, isOpenLocal, isOpen])

    return (
        <div className={clsx(styles.wrapper, className)}>
            <div className={clsx(!customTitleContainer && styles.titleContainer, featured && styles.featured)} onClick={resolveOnClick}>
                {customTitleContainer ? customTitleContainer : (<>
                    <ChevronIcon className={clsx(styles.chevron, resolvedIsOpen && styles.isOpen)}/>
                    <div className={styles.title} dangerouslySetInnerHTML={{__html: title}}></div>
                </>)}
            </div>
            <div className={clsx(styles.content, resolvedIsOpen && styles.isOpen)}>{content}</div>
        </div>
    );
}

export default Accordion;
