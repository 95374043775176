// extracted by mini-css-extract-plugin
export var hideBelowXl = "Pricing-module--hideBelowXl--vxobD";
export var hideOverXl = "Pricing-module--hideOverXl--tH1al";
export var hideBelowL = "Pricing-module--hideBelowL---TvMA";
export var hideOverL = "Pricing-module--hideOverL--BCGKX";
export var hideBelowM = "Pricing-module--hideBelowM--waY2A";
export var hideOverM = "Pricing-module--hideOverM--PFvVZ";
export var hideBelowS = "Pricing-module--hideBelowS--tkPjE";
export var hideOverS = "Pricing-module--hideOverS--+BMxc";
export var hideBelowXs = "Pricing-module--hideBelowXs--x6PMW";
export var hideOverXs = "Pricing-module--hideOverXs--5rf-5";
export var container = "Pricing-module--container--auRJW";
export var content = "Pricing-module--content--YQXff";
export var pizzaContainer = "Pricing-module--pizzaContainer--m4k9F";
export var buttons = "Pricing-module--buttons--AOwRk";