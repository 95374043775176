// extracted by mini-css-extract-plugin
export var hideBelowXl = "MakeLearningEasier-module--hideBelowXl--3Hh3i";
export var hideOverXl = "MakeLearningEasier-module--hideOverXl--TjbgJ";
export var hideBelowL = "MakeLearningEasier-module--hideBelowL--QUYBs";
export var hideOverL = "MakeLearningEasier-module--hideOverL--rb5m3";
export var hideBelowM = "MakeLearningEasier-module--hideBelowM--RFykW";
export var hideOverM = "MakeLearningEasier-module--hideOverM--xaJii";
export var hideBelowS = "MakeLearningEasier-module--hideBelowS--1N8Qu";
export var hideOverS = "MakeLearningEasier-module--hideOverS--L0KYY";
export var hideBelowXs = "MakeLearningEasier-module--hideBelowXs--od-Ld";
export var hideOverXs = "MakeLearningEasier-module--hideOverXs--IXXDU";
export var content = "MakeLearningEasier-module--content--z9thk";
export var bulletsContainer = "MakeLearningEasier-module--bulletsContainer--+vuGo";
export var bullet = "MakeLearningEasier-module--bullet--QXppZ";
export var image = "MakeLearningEasier-module--image--s00Tv";