/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import ogImage from 'images/og_image.png';

function Seo({ description, lang, meta, title, keywords }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'CACHE-CONTROL',
          content: 'NO-CACHE',
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: `${site.siteMetadata.url}${ogImage}`,
        },
        {
          property: 'og:url',
          content: site.siteMetadata.url,
        },
        {
          property: 'og:locale',
          content: 'pl_PL',
        },
        {
          property: 'keywords',
          content: keywords || 'matura, pomoc matura, zadania maturalne, matura aplikacja, arkusze maturalne, matura rozwiązania, nauka do matury',
        },
        {
          property: 'fb:app_id',
          content: '370242740567642',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image',
          content: `${site.siteMetadata.url}${ogImage}`,
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        }, {
          name: 'al:android:url',
          content: "https://play.google.com/store/apps/details?id=pl.edu.mojamatura.app.twa",
        }, {
          name: 'al:android:app_name',
          content: "Moja Matura",
        }, {
          name: 'al:android:package',
          content: "pl.edu.mojamatura.app.twa",
        },
      ].concat(meta)}
      title={title}
    />
  );
}

Seo.defaultProps = {
  lang: 'pl',
  meta: [],
  description: '',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
