import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import PropTypes from 'prop-types';

import GA_EVENTS from 'constants/ga_events';
import MuteIcon from 'images/svg/mute.svg';
import * as common from 'styles/common.module.scss';
import useIntersect from 'utils/useIntersect';

import * as styles from './VideoPlayer.module.scss';

const VideoPlayer = ({ auto_play, loop, poster, movie_files, className, title }) => {
  const videoRef = useRef();
  const [inView] = useIntersect(videoRef, { threshold: 0, rootMargin: '0% 15%', keepObserving: true });

  const [isVideoPlaying, setIsVideoPlaying] = useState(null);
  const [wasVideoPlaying, setWasVideoPlaying] = useState(null);
  const [isVideoMuted, setIsVideoMuted] = useState(null);
  const onVideoClick = () => {
    if (videoRef.current.paused) videoRef.current.play();
    else videoRef.current.pause();
    setWasVideoPlaying(!videoRef.current.paused);
  };
  const onVideoPause = () => {
    trackCustomEvent({
      category: GA_EVENTS.USER_INTERACTION,
      action: `Header video paused`,
    });
    setIsVideoPlaying(false);
  };
  const onVideoPlay = () => {
    trackCustomEvent({
      category: GA_EVENTS.USER_INTERACTION,
      action: `Header video played`,
    });
    setIsVideoPlaying(true);
  };
  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    trackCustomEvent({
      category: GA_EVENTS.USER_INTERACTION,
      action: `${videoRef.current.muted ? 'Mute' : 'Unmute'} header video`,
    });
    setIsVideoMuted(videoRef.current.muted);
  };

  useEffect(() => {
    if (!inView) videoRef.current.pause();
    if (inView && wasVideoPlaying) videoRef.current.play();
  }, [inView]);

  useEffect(() => {
    if (videoRef) {
      setIsVideoPlaying(!videoRef.current.paused);
      setIsVideoMuted(videoRef.current.muted);
    }
  }, [videoRef]);

  return (
    <div className={clsx(styles.videoWrapper, isVideoPlaying === false && styles.paused, className)}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoRef}
        autoPlay={auto_play}
        className={styles.video}
        disablePictureInPicture
        loop={loop}
        onClick={onVideoClick}
        onPause={onVideoPause}
        onPlay={onVideoPlay}
        playsInline
        poster={poster && poster.localFile.url}
      >
        {movie_files &&
          movie_files.map(({ id, file_type, file }) => <source key={id} src={file.localFile.url} type={`video/${file_type}`} />)}
      </video>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/control-has-associated-label,jsx-a11y/interactive-supports-focus */}
      <div
        className={clsx(styles.videoCaption, isVideoPlaying === false && styles.paused, common.hideBelowS)}
        onClick={onVideoClick}
        role='button'
        aria-label='Uruchom/zapauzuj film'
      >
        {title}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/control-has-associated-label,jsx-a11y/interactive-supports-focus */}
      <div className={clsx(styles.videoPlayIcon, isVideoPlaying === false && styles.paused)} onClick={onVideoClick} role='button' />
      <button
        className={clsx(styles.muteButton, !isVideoMuted && styles.muted, !isVideoPlaying && styles.hidden)}
        onClick={toggleMute}
        type='button'
        aria-label='Włącz/wyłącz dźwięk'
      >
        <MuteIcon />
      </button>
    </div>
  );
};

VideoPlayer.propTypes = {
  auto_play: PropTypes.bool.isRequired,
  loop: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  poster: PropTypes.shape({
    localFile: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  movie_files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      file_type: PropTypes.string,
      file: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  ).isRequired,
  className: PropTypes.string,
};

VideoPlayer.defaultProps = {
  className: null,
};

export default VideoPlayer;
