import React, { useEffect, useRef } from 'react';

import clsx from 'clsx';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import PropTypes from 'prop-types';

import GA_EVENTS from 'constants/ga_events';
import useIntersect from 'utils/useIntersect';

import * as styles from './Section.module.scss';

const Section = ({ children, className, animation, _scrollId, ...rest }) => {
  const sectionRef = useRef();
  const [inView] = useIntersect(sectionRef, { threshold: 0, rootMargin: undefined });

  useEffect(() => {
    if (inView) {
      trackCustomEvent({
        category: GA_EVENTS.SCROLL,
        action: `Doscrollowano do sekcji "${_scrollId}"`,
      });
    }
  }, [inView]);

  return (
    <section ref={sectionRef} className={clsx(styles.section, !inView && animation && styles.outOfView, className)} {...rest}>
      {children}
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  _scrollId: PropTypes.string,
  animation: PropTypes.bool,
};

Section.defaultProps = {
  className: null,
  animation: true,
  _scrollId: 'Brak nazwy',
};

export default Section;
