// extracted by mini-css-extract-plugin
export var hideBelowXl = "Subjects-module--hideBelowXl--7jofp";
export var hideOverXl = "Subjects-module--hideOverXl--+8FNu";
export var hideBelowL = "Subjects-module--hideBelowL--aaZCj";
export var hideOverL = "Subjects-module--hideOverL--19IZT";
export var hideBelowM = "Subjects-module--hideBelowM--S9wOm";
export var hideOverM = "Subjects-module--hideOverM--Tzp8a";
export var hideBelowS = "Subjects-module--hideBelowS--hOr2S";
export var hideOverS = "Subjects-module--hideOverS--ccGi0";
export var hideBelowXs = "Subjects-module--hideBelowXs--J2Wwe";
export var hideOverXs = "Subjects-module--hideOverXs---r5n7";
export var content = "Subjects-module--content--bjrN3";
export var description = "Subjects-module--description--c1o7D";
export var subjectsContainer = "Subjects-module--subjectsContainer--EIjMi";
export var subject = "Subjects-module--subject--Rz12p";
export var icon = "Subjects-module--icon--yfN2D";
export var subjectName = "Subjects-module--subjectName--lcQrO";
export var tocLink = "Subjects-module--tocLink--x6+U0";