// extracted by mini-css-extract-plugin
export var hideBelowXl = "PricingDetails-module--hideBelowXl--56BtB";
export var hideOverXl = "PricingDetails-module--hideOverXl--lSYkp";
export var hideBelowL = "PricingDetails-module--hideBelowL--T0-j3";
export var hideOverL = "PricingDetails-module--hideOverL--lS7GB";
export var hideBelowM = "PricingDetails-module--hideBelowM--vKLnb";
export var hideOverM = "PricingDetails-module--hideOverM--hKQL-";
export var hideBelowS = "PricingDetails-module--hideBelowS--PFDUl";
export var hideOverS = "PricingDetails-module--hideOverS--vcvXy";
export var hideBelowXs = "PricingDetails-module--hideBelowXs--j-GS9";
export var hideOverXs = "PricingDetails-module--hideOverXs--KgZ6E";
export var container = "PricingDetails-module--container--VPKwN";
export var buttonWrapper = "PricingDetails-module--buttonWrapper--mgxg5";
export var pricesList = "PricingDetails-module--pricesList--9skPl";