// extracted by mini-css-extract-plugin
export var hideBelowXl = "PriceTile-module--hideBelowXl--TFmEz";
export var hideOverXl = "PriceTile-module--hideOverXl--5xBx4";
export var hideBelowL = "PriceTile-module--hideBelowL--FDjlh";
export var hideOverL = "PriceTile-module--hideOverL--e8r-p";
export var hideBelowM = "PriceTile-module--hideBelowM--joz2D";
export var hideOverM = "PriceTile-module--hideOverM--ezNLj";
export var hideBelowS = "PriceTile-module--hideBelowS--xgkEP";
export var hideOverS = "PriceTile-module--hideOverS--E5Msw";
export var hideBelowXs = "PriceTile-module--hideBelowXs--UDHiD";
export var hideOverXs = "PriceTile-module--hideOverXs--bxJ-+";
export var container = "PriceTile-module--container--yAcFh";
export var featured = "PriceTile-module--featured--GrW9R";
export var title = "PriceTile-module--title--HuwZA";
export var section = "PriceTile-module--section--L7HT-";
export var divider = "PriceTile-module--divider--3wtpr";
export var sectionTop = "PriceTile-module--sectionTop--qB7mf";
export var mainText = "PriceTile-module--mainText--pTTym";
export var caption = "PriceTile-module--caption--6b0sZ";