// extracted by mini-css-extract-plugin
export var hideBelowXl = "Section-module--hideBelowXl--exKAz";
export var hideOverXl = "Section-module--hideOverXl--kxlwL";
export var hideBelowL = "Section-module--hideBelowL--7EPIo";
export var hideOverL = "Section-module--hideOverL--MKkmU";
export var hideBelowM = "Section-module--hideBelowM--SENUu";
export var hideOverM = "Section-module--hideOverM--C3hjE";
export var hideBelowS = "Section-module--hideBelowS--GQ478";
export var hideOverS = "Section-module--hideOverS--h+K1U";
export var hideBelowXs = "Section-module--hideBelowXs--aADXx";
export var hideOverXs = "Section-module--hideOverXs--PcJqw";
export var section = "Section-module--section--5iaF4";
export var outOfView = "Section-module--outOfView--coqIb";