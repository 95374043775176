// extracted by mini-css-extract-plugin
export var hideBelowXl = "Benefits-module--hideBelowXl--Xc7Zr";
export var hideOverXl = "Benefits-module--hideOverXl--BOQAN";
export var hideBelowL = "Benefits-module--hideBelowL--pnyuf";
export var hideOverL = "Benefits-module--hideOverL--b-NYu";
export var hideBelowM = "Benefits-module--hideBelowM--fa2XQ";
export var hideOverM = "Benefits-module--hideOverM--zK+7p";
export var hideBelowS = "Benefits-module--hideBelowS--ZLMrV";
export var hideOverS = "Benefits-module--hideOverS--C891N";
export var hideBelowXs = "Benefits-module--hideBelowXs--qSaJJ";
export var hideOverXs = "Benefits-module--hideOverXs--aJd12";
export var content = "Benefits-module--content--WaxOw";
export var bulletsContainer = "Benefits-module--bulletsContainer--qcoio";
export var bullet = "Benefits-module--bullet--MueHx";