// extracted by mini-css-extract-plugin
export var hideBelowXl = "Opinions-module--hideBelowXl--w-hUN";
export var hideOverXl = "Opinions-module--hideOverXl--PbMyk";
export var hideBelowL = "Opinions-module--hideBelowL--fbhER";
export var hideOverL = "Opinions-module--hideOverL--ZT9Hb";
export var hideBelowM = "Opinions-module--hideBelowM--d4knn";
export var hideOverM = "Opinions-module--hideOverM--R7MD4";
export var hideBelowS = "Opinions-module--hideBelowS--bNWlQ";
export var hideOverS = "Opinions-module--hideOverS--DMEcs";
export var hideBelowXs = "Opinions-module--hideBelowXs--wx1Lj";
export var hideOverXs = "Opinions-module--hideOverXs--Jo3Nw";
export var opinions = "Opinions-module--opinions--TpFO4";
export var content = "Opinions-module--content--MoUX2";
export var swipeIconWrapper = "Opinions-module--swipeIconWrapper--Nei3M";
export var imageWrapper = "Opinions-module--imageWrapper--ZhbQn";
export var image = "Opinions-module--image--Bnoc5";