import React from 'react';

import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Section from 'components/Section';
import CheckIcon from 'images/svg/check_mark.svg';
import * as common from 'styles/common.module.scss';

import * as styles from './Benefits.module.scss';

const query = graphql`
  query BenefitsQuery {
    strapiBenefits {
      title
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 950, placeholder: TRACED_SVG)
          }
        }
      }
      benefit {
        id
        text
      }
    }
  }
`;

const Benefits = () => {
  const { strapiBenefits } = useStaticQuery(query);
  return (
    <Section _scrollId='Benefity (4)' className={clsx(common.container)}>
      <h2 className={clsx(common.sectionTitle, common.sectionTitle_titleSpacing)}>{strapiBenefits.title}</h2>
      <div className={styles.content}>
        <div className={styles.bulletsContainer}>
          {strapiBenefits.benefit.map(({ id, text }) => (
            <div key={id} className={styles.bullet}>
              <CheckIcon />
              <p>{text}</p>
            </div>
          ))}
        </div>
        <GatsbyImage alt={strapiBenefits.image.alternativeText} image={getImage(strapiBenefits.image.localFile)} />
      </div>
    </Section>
  );
};

export default Benefits;
