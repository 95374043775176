import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Section from 'components/Section';
import Diagram from 'images/svg/how_it_works_diagram.svg';
import DiagramMobile from 'images/svg/how_it_works_diagram_mobile.svg';
import * as common from 'styles/common.module.scss';
import isBrowser from 'utils/isBrowser';

import * as styles from './HowItWorks.module.scss';
import createOutboundLinkHref from "utils/createOutboundLinkHref";

const query = graphql`
  query HowItWorksQuery {
    strapiHowItWorks {
      image {
        image {
          alternativeText
          id
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300, placeholder: TRACED_SVG)
            }
          }
        }
      }
      steps {
        text
        id
      }
      title
    }
  }
`;

const HowItWorks = () => {
  const { strapiHowItWorks } = useStaticQuery(query);
  const [mobileDiagram, setMobileDiagram] = useState(false);

  const checkDiagram = () => {
    setMobileDiagram(window.innerWidth <= 768);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isBrowser) {
      checkDiagram();
      window.addEventListener('resize', checkDiagram);
      return () => window.removeEventListener('resize', checkDiagram);
    }
  }, []);

  return (
    <Section _scrollId='Jak to działa (2)' className={clsx(common.containerTight)}>
      <h2 className={common.sectionTitle}>{strapiHowItWorks.title}</h2>
      <div className={styles.content}>
        <div className={styles.diagramContainer}>
          {mobileDiagram ? <DiagramMobile /> : <Diagram />}
          <ul className={styles.diagramContent}>
            {strapiHowItWorks.steps.map(({ text, id }) => (
              <li key={id} className={styles.step}>
                {text}
              </li>
            ))}
          </ul>
          <OutboundLink
            className={clsx(common.button, styles.button)}
            href={createOutboundLinkHref('https://app.mojamatura.edu.pl/auth')}
            rel='noopener'
            target='_blank'
          >
            Załóż darmowe konto
          </OutboundLink>
        </div>
        <div className={styles.images}>
          {strapiHowItWorks.image.map(({ image: { localFile, alternativeText, id } }) => (
            <GatsbyImage key={id} alt={alternativeText} image={getImage(localFile)} />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default HowItWorks;
