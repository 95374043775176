import React from 'react';

import clsx from 'clsx';

import Section from 'components/Section';
import * as common from 'styles/common.module.scss';

import * as styles from './Schools.module.scss';


const Schools = () => {
  return (
    <Section _scrollId='Szkoly (7)' className={clsx(common.containerTight)} id={'szkoły'} >
      <h2 className={clsx(common.sectionTitle, common.sectionTitle_titleSpacing)}>Moja Matura w szkołach</h2>
      <div className={styles.content}>
          <p className={styles.description}>Moja Matura z powodzeniem jest używana w szkołach jako wsparcie dla nauczycieli oraz uczniów.</p>
          <p className={styles.description}> <a target="_blank" href={'mailto:kontakt@mojamatura.edu.pl?subject=Umówienie pilotażu aplikacji Moja Matura'}>Skontaktuj się z nami</a>, aby omówić szczegóły bezpłatnego pilotażu oraz możliwe sposoby wprowadzenia aplikacji do szkoły.</p>
      </div>
    </Section>
  );
};

export default Schools;
