// extracted by mini-css-extract-plugin
export var hideBelowXl = "Schools-module--hideBelowXl--d8EAP";
export var hideOverXl = "Schools-module--hideOverXl--eGJFa";
export var hideBelowL = "Schools-module--hideBelowL--WCpjX";
export var hideOverL = "Schools-module--hideOverL--NBpji";
export var hideBelowM = "Schools-module--hideBelowM--uNQVT";
export var hideOverM = "Schools-module--hideOverM--d8cnG";
export var hideBelowS = "Schools-module--hideBelowS--fviPS";
export var hideOverS = "Schools-module--hideOverS--kiE7N";
export var hideBelowXs = "Schools-module--hideBelowXs--LE15b";
export var hideOverXs = "Schools-module--hideOverXs--F+lcw";
export var content = "Schools-module--content--EA7D1";
export var description = "Schools-module--description--F-VXY";