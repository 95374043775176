import React, {useState} from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import * as common from 'styles/common.module.scss';

import * as styles from './PriceTile.module.scss';
import Accordion from "../Accordion";



const PriceTile = ({name, mainText, caption, button, elements, featured}) => {
    const [ openElement, setOpenElement] = useState(null);

    const onElementClick = (id) => {
        if(openElement !== id) setOpenElement(id)
        else setOpenElement(null)
    };

    return (
        <article className={clsx(styles.container, featured && styles.featured)}>
            <h3 className={styles.title}>{name}</h3>
            <div className={clsx(styles.section, styles.sectionTop)}>
                <div className={styles.mainText}>{mainText}</div>
                <div className={styles.caption}>{caption}</div>
                {button}
            </div>
            <div className={styles.divider}></div>
            <div className={styles.section}>
                {elements.map(({title, description, featured}) => {
                    return <Accordion featured={featured} title={title} content={description} controlled onClick={onElementClick} id={title} isOpen={title === openElement}  />
                })}
            </div>
        </article>
    );
}

PriceTile.defaultProps = {
    price_variant: null,
    promotion_text: null,
};

export default PriceTile;
