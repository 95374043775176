// extracted by mini-css-extract-plugin
export var hideBelowXl = "VideoPlayer-module--hideBelowXl--scxJO";
export var hideOverXl = "VideoPlayer-module--hideOverXl--0La65";
export var hideBelowL = "VideoPlayer-module--hideBelowL----t4t";
export var hideOverL = "VideoPlayer-module--hideOverL--nVLWh";
export var hideBelowM = "VideoPlayer-module--hideBelowM--Bx4YE";
export var hideOverM = "VideoPlayer-module--hideOverM--K9jRD";
export var hideBelowS = "VideoPlayer-module--hideBelowS--nut81";
export var hideOverS = "VideoPlayer-module--hideOverS--v7oxQ";
export var hideBelowXs = "VideoPlayer-module--hideBelowXs--WTuqc";
export var hideOverXs = "VideoPlayer-module--hideOverXs--oz64F";
export var video = "VideoPlayer-module--video--KzaBE";
export var videoWrapper = "VideoPlayer-module--videoWrapper--G4T6q";
export var _videoElements = "VideoPlayer-module--_videoElements--i5MOv";
export var videoPlayIcon = "VideoPlayer-module--videoPlayIcon---qNO9";
export var videoCaption = "VideoPlayer-module--videoCaption--ZLJT8";
export var paused = "VideoPlayer-module--paused--IwLYH";
export var muteButton = "VideoPlayer-module--muteButton--pF-U8";
export var hidden = "VideoPlayer-module--hidden--cEbce";
export var muted = "VideoPlayer-module--muted--utVFl";