import React from 'react';

import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import { marked } from 'marked';

import Section from 'components/Section';
import * as common from 'styles/common.module.scss';
import importIcons from 'utils/importIcons';

import * as styles from './Subjects.module.scss';

const icons = importIcons(require.context('images/svg/subjects', false, /\.(svg)$/));

const query = graphql`
  query SubjectsQuery {
    strapiSubjects {
      title
      description
      subject {
        icon
        id
        name
        table_of_content {
          localFile {
            url
          }
        }
      }
    }
  }
`;

const Subjects = () => {
  const { strapiSubjects } = useStaticQuery(query);
  return (
    <Section _scrollId='Przedmioty (6)' className={clsx(common.containerTight)}>
      <h2 className={clsx(common.sectionTitle, common.sectionTitle_titleSpacing)}>{strapiSubjects.title}</h2>
      <div className={styles.content}>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: marked.parse(strapiSubjects.description) }} />
        <div className={styles.subjectsContainer}>
          {strapiSubjects.subject.map(({ icon, id, name, table_of_content: { localFile } }) => {
            const Icon = icons[icon];
            return (
              <a key={id} className={styles.subject} href={localFile.url} rel='noreferrer' target='_blank'>
                <Icon className={styles.icon} />
                <p className={styles.subjectName}>{name}</p>
              </a>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default Subjects;
