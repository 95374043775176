import React from 'react';

import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Section from 'components/Section';
import * as common from 'styles/common.module.scss';
import importIcons from 'utils/importIcons';

import * as styles from './MakeLearningEasier.module.scss';

const icons = importIcons(require.context('images/svg/make_learning_easier/', false, /\.(svg)$/));

const query = graphql`
  query MakeLearningEasierQuery {
    strapiMakeLearningEasier {
      title
      bullets {
        icon
        id
        text
      }
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 300, placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
`;

const MakeLearningEasier = () => {
  const { strapiMakeLearningEasier } = useStaticQuery(query);
  return (
    <Section _scrollId='Ułatwiamy naukę (3)' className={clsx(common.containerTight)}>
      <h2 className={common.sectionTitle}>{strapiMakeLearningEasier.title}</h2>
      <div className={styles.content}>
        <div className={styles.bulletsContainer}>
          {strapiMakeLearningEasier.bullets.map(({ icon, text, id }) => {
            const Icon = icons[icon];
            return (
              <div key={id} className={styles.bullet}>
                <Icon />
                <p>{text}</p>
              </div>
            );
          })}
        </div>
        <div className={styles.image}>
          <GatsbyImage alt={strapiMakeLearningEasier.image.alternativeText} image={getImage(strapiMakeLearningEasier.image.localFile)} />
        </div>
      </div>
    </Section>
  );
};

export default MakeLearningEasier;
