import React from 'react';

import clsx from 'clsx';
import {trackCustomEvent, OutboundLink} from 'gatsby-plugin-google-analytics';

import PriceTile from 'components/PriceTile';
import Section from 'components/Section';
import GA_EVENTS from 'constants/ga_events';
import * as common from 'styles/common.module.scss';

import * as styles from './Pricing.module.scss';
import createOutboundLinkHref from "utils/createOutboundLinkHref";
import PricingDetails from "components/PricingDetails/PricingDetails";


const freeElements = [
    {
        title: 'Filtrowanie i sortowanie zadań',
        description: 'Zadania możesz filtrować po działach, poddziałach, słowach kluczowych oraz wielu innych',
    },

    {
        title: 'Oznaczanie zadań',
        description: 'Każde zadanie możesz oznaczać czy sprawiło Ci trudność czy nie. W każdej chwili możesz wrócić do wcześniej oznaczonych zadań.',
    },

    {
        title: 'Dostęp do ograniczonej puli zadań',
        description: 'Masz dostęp do całego materiału z matematyki podstawowej oraz do jednego działu z pozostałych przedmiotów',
    },

];
const premiumElements = [


    {
        title: 'Filtrowanie i sortowanie zadań',
        description: 'Zadania możesz filtrować po działach, poddziałach, słowach kluczowych oraz wielu innych',
    },

    {
        title: 'Oznaczanie zadań',
        description: 'Każde zadanie możesz oznaczać czy sprawiło Ci trudność czy nie. W każdej chwili możesz wrócić do wcześniej oznaczonych zadań.',
    },

    {
        title: 'Dostęp do <strong>wszystkich</strong> zadań ze wszystkich przedmiotów',
        description: 'Masz dostęp do całego materiału ze wszystkich przedmiotów',
    },

    {
        title: 'Możliwość przeglądania zadań w formie arkuszy maturalnych',
        description: 'Możesz przejrzeć wszystkie archiwalne matury w przystępnej elektronicznej formie',
    },

    {
        title: 'Zapisywanie zestawów zadań',
        description: 'Możesz stworzyć swój własny zestaw zadań do którego możesz wrócić w każdej chwili.',
    },

];
const schoolElements = [


    {
        title: 'To samo co w Planie Premium',
        description: 'Wszytskie funkcjonalności dostępne w Planie Premium',
        featured: true,
    },

    {
        title: 'Tworzenie klas oraz panel nauczyciela',
        description: 'Możliwość łączenia użytkowników w klasy wraz z przypisaniem nauczyciela do klasy. Nauczyciel ma wgląd w pracę uczniów swoich klas.',
    },

    {
        title: 'Wsparcie premium',
        description: 'Bezpośrednie wsparcie w korzystaniu z aplikacji oraz możliwość zoorganizowania warsztatów pokazujących sposoby pracy z aplikacją.',

    },

];

const Pricing = () => {
    const scrollTop = () => {
        trackCustomEvent({
            category: GA_EVENTS.INTERNAL_ROUTING,
            action: 'Użyto przycisku "powrót na górę"',
        });
        window.scrollTo(0, 0);
    };

    return (
        <>
            <Section _scrollId='Cennik (8)' className={clsx(common.container, styles.container)} id='cennik'>
                <h2 className={clsx(common.sectionTitle, common.sectionTitle_titleSpacing)}>Zarejestruj się za darmo i
                    sprawdź. Kupić możesz później
                </h2>
                <div className={styles.content}>
                    <PriceTile
                        name={'Plan darmowy'}
                        mainText={'Ozł'}
                        button={
                            <OutboundLink
                                className={common.button}
                                href={createOutboundLinkHref('https://app.mojamatura.edu.pl/auth')}
                                rel='noopener'
                                target='_blank'
                            >
                                Załóż konto za darmo
                            </OutboundLink>
                        }
                        elements={freeElements}
                    />
                    <PriceTile
                        featured
                        caption='Przy zakupie planu na rok'
                        name={'Plan premium'}
                        mainText={'Od 16,67zł za miesiąc'}
                        button={<PricingDetails/>}
                        elements={premiumElements}
                    />

                    <PriceTile
                        caption={'Skontaktuj się z nami w celu umówienia bezpłatnego pilotażu '}
                        name={'Plan dla szkół'}
                        mainText={'Indywidualna wycena'}
                        button={
                            <OutboundLink
                                className={common.button}
                                href={createOutboundLinkHref('mailto:kontakt@mojamatura.edu.pl?subject=Umówienie pilotażu aplikacji Moja Matura')}
                                rel='noopener'
                                target='_blank'
                            >
                                Umów pilotaż
                            </OutboundLink>
                        }
                        elements={schoolElements}
                    />
                </div>
            </Section>
            <Section>
                <div className={styles.pizzaContainer}>
                    {/*<GatsbyImage alt={strapiPricing.pizza_image.alternativeText} image={getImage(strapiPricing.pizza_image.localFile)} />*/}
                    <div className={styles.buttons}>
                        <OutboundLink
                            className={common.button}
                            href={createOutboundLinkHref('https://app.mojamatura.edu.pl/auth')}
                            rel='noopener'
                            target='_blank'
                        >
                            Załóż darmowe konto
                        </OutboundLink>
                        <button className={common.buttonInverted} onClick={scrollTop} type='button'>
                            Powrót na górę
                        </button>
                    </div>
                </div>
            </Section>
        </>
    );
};

export default Pricing;
