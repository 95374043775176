import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { StaticImage } from 'gatsby-plugin-image';

import Section from 'components/Section';
import VideoPlayer from 'components/VideoPlayer';
import ChromeButton from 'images/svg/chrome_button.svg';
import SafariButton from 'images/svg/safari_button.svg';
import * as common from 'styles/common.module.scss';
import isBrowser from 'utils/isBrowser';

import * as styles from './Hero.module.scss';

const query = graphql`
  query HeroQuery {
    strapiHeroSection {
      title
      content
      safari_link
      chrome_link
      movie_desktop {
        auto_play
        loop
        poster {
          localFile {
            url
          }
        }
        movie_files {
          id
          file_type
          file {
            localFile {
              url
            }
          }
        }
      }
      movie_mobile {
        auto_play
        loop
        movie_files {
          id
          file_type
          file {
            localFile {
              url
            }
          }
        }
        poster {
          localFile {
            url
          }
        }
      }
    }
  }
`;

const VIDEO_BREAKPOINT = 1000;

const Hero = () => {
  const { strapiHeroSection } = useStaticQuery(query);

  // VIDEO
  const [videoSource, setVideoSource] = useState({});
  const setVideo = () => {
    if (isBrowser) {
      setVideoSource(window.innerWidth > VIDEO_BREAKPOINT ? 'movie_desktop' : 'movie_mobile');
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isBrowser) {
      setVideo();
      window.addEventListener('resize', setVideo);
      return () => window.removeEventListener('resize', setVideo);
    }
  }, []);

  return (
    <Section _scrollId='Hero (1)' animation={false} className={clsx(common.container, styles.hero)} id='hero'>
      <h1 className={clsx(common.sectionTitle, common.sectionTitle_leftAlignment, styles.title, styles.text)}>{strapiHeroSection.title}</h1>
      <p className={clsx(styles.text)}>{strapiHeroSection.content}</p>
      <div className={styles.installationManuals}>
        <OutboundLink
          className={clsx(styles.installationManualButton, styles.installationManualButton_google)}
          href='https://play.google.com/store/apps/details?id=pl.edu.mojamatura.app.twa'
          rel='noopener'
          target='_blank'
        >
          <StaticImage alt='Moja matura - Google play badge' className={styles.image} src='../../images/google_play.png' />
        </OutboundLink>
        <OutboundLink
          className={styles.installationManualButton}
          href={strapiHeroSection.chrome_link}
          rel='noopener'
          target='_blank'
          type='button'
        >
          <ChromeButton className={styles.icon} />
          <p className={styles.subtitle}>Instalacja w Chrome</p>
        </OutboundLink>
        <OutboundLink
          className={styles.installationManualButton}
          href={strapiHeroSection.safari_link}
          rel='noopener'
          target='_blank'
          type='button'
        >
          <SafariButton className={styles.icon} />
          <p className={styles.subtitle}>Instalacja na Safari</p>
        </OutboundLink>
      </div>
      {strapiHeroSection[videoSource] && (
        <VideoPlayer className={styles.video} title='Zobacz jak działa nasza aplikacja' {...strapiHeroSection[videoSource]} />
      )}
    </Section>
  );
};

export default Hero;
